/*eslint-disable*/
import React from "react";
import Link from "next/link";
import { useRouter } from 'next/router';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

// core components
import CustomDropdown from "/components/CustomDropdown/CustomDropdown.js";
import Button from "/components/CustomButtons/Button.js";

import navbarsStyle from "/styles/jss/bbhost/pages/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(navbarsStyle, { name: "headerNavItem" });
import { getLangFromObj } from "lang/lang.js";

export default function HeaderNavItem({nav, active_name}) {
  const classes = useStyles();
  const { locale } = useRouter();
  
  let item = (<></>);

  if(typeof nav.text === "object")
  {
    nav.text = getLangFromObj(nav.text, locale);
  }

  if(nav.type == "link")
  {
    item = (<Link href={nav.route} as={nav.route}>
      <Button
        href={nav.route}
        color="transparent"
        className={classes.navLink + " " + (nav.name == active_name ? classes.navLinkActive: "")}
      >
        {nav.text}
      </Button>
    </Link> );
  }
  else if(nav.type == "dropdown")
  {
      let activeMenu = {
        active: false,
        name: ""
      }
      const itensList = nav.itens.map((item, key) => {
        if(typeof item.text === "object")
        {
          item.text = getLangFromObj(item.text, locale);
        }

        if(item.name == active_name)
        {
          activeMenu.active = true;
          activeMenu.name = item.name;
          return (
            <Button
              href={item.route}
              color="info"
              className={classes.dropdownLink}
            >
              {item.text}
            </Button>
          );
        }
        return (<Link key={item.name} href={item.route}>
          <a className={classes.dropdownLink}>{item.text}</a>
        </Link>);
      });
      
      
      item = (<CustomDropdown
        noLiPadding
        navDropdown
        hoverColor="info"
        buttonText={nav.text}
        buttonProps={{
          className: classes.navLink + " " + (activeMenu.active ? classes.navLinkActive: ""),
          color: "transparent",
        }}
        dropdownList={itensList}
      />);
  }
  return (
    <ListItem className={classes.listItem}>
        {item} 
    </ListItem>
  );
}
